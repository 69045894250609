import React, { Component, Suspense, lazy } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LayoutHOC from "../modules/common/layout-hoc";
import HeaderLayout from "./HeaderLayout";

// landing
const Forbidden = lazy(() => import("../modules/forbidden"));
const SiteLanding = lazy(() => import("../modules/site"));
const Signin = lazy(() => import("../modules/landing/signin"));
const Signup = lazy(() => import("../modules/landing/signup"));
const Forgotpassword = lazy(() => import("../modules/landing/forgotpassword"));
const Resetpassword = lazy(() => import("../modules/landing/resetpassword"));
const AboutUs = lazy(() => import("../modules/site/about-us"));
const Products = lazy(() => import("../modules/site/products"));
const Pricing = lazy(() => import("../modules/site/pricing"));
const FreeTrail = lazy(() => import("../modules/landing/free-trail"));
const BookDemo = lazy(() => import("../modules/landing/book-demo"));
const ContactUs = lazy(() => import("../modules/site/contact"));
const SupplierManagement = lazy(() =>
  import("../modules/site/supplier-management")
);
const PurchaseRequisition = lazy(() =>
  import("../modules/site/purchase-requisition")
);
const ContractManagement = lazy(() =>
  import("../modules/site/contract-management")
);
const EInvoicing = lazy(() => import("../modules/site/e-invoicing"));
const ESourcing = lazy(() => import("../modules/site/e-sourcing"));
const SpendAnalytics = lazy(() => import("../modules/site/spend-analytics"));
const Integrations = lazy(() => import("../modules/site/integrations"));
const Blogs = lazy(() => import("../modules/site/blogs"));
const BlogDetails = lazy(() => import("../modules/site/blogs/blog-view"));
const PrivacyPolicy = lazy(() => import("../modules/site/privacy-policy"));
const Careers = lazy(() => import("../modules/site/careers"));

// supplier
const CompanyDetails = lazy(() =>
  import("../modules/supplier/company-details")
);
const ScopeofSupply = lazy(() => import("../modules/supplier/scopeof-supply"));
const ContactDetails = lazy(() =>
  import("../modules/supplier/contact-details")
);
const Banking = lazy(() => import("../modules/supplier/banking"));
const Certificates = lazy(() => import("../modules/supplier/certificates"));
const Dashboard = lazy(() => import("../modules/dashboard"));
const SupplierApproval = lazy(() => import("../modules/supplier/sup-aproval"));
const SupplierListing = lazy(() => import("../modules/supplier/sup-listing"));
const UserFeedback = lazy(() => import("../modules/common/feedback"));
//const FeedbackAnalysis = lazy(() => import('../modules/common/feedback-analysis'));
//const SupplierSurvey = lazy(() => import('../modules/supplier/survey'));
//const NewSurvey = lazy(() => import('../modules/supplier/new-survey'));
//const SurveyTemplate = lazy(() => import('../modules/supplier/survey-template'));
// const CreateTemplate = lazy(() => import('../modules/supplier/create-template'));
//const SelectSupplier = lazy(() => import('../modules/supplier/select-supplier'));
const SupplierPreview = lazy(() => import("../modules/supplier/sup-preview"));
// esource
const PRLanding = lazy(() => import("../modules/esource/pr-landing"));
//const NewPR = lazy(() => import('../modules/esource/new-pr'));
const PRPreview = lazy(() => import("../modules/esource/pr-preview"));
const POLanding = lazy(() => import("../modules/esource/po-landing"));
const POPreview = lazy(() => import("../modules/esource/po-preview"));
//const NewPO = lazy(() => import('../modules/esource/new-po'));
// invoice
const InvoiceLanding = lazy(() => import("../modules/invoicemgmnt/landing"));
//const CreateInvoice = lazy(() => import('../modules/invoicemgmnt/new-invoice'));
const NonPOInvoice = lazy(() =>
  import("../modules/invoicemgmnt/landing/non-po-invoice")
);
//const AdvancedInvoice = lazy(() => import('../modules/invoicemgmnt/advanced-invoice'));
const PreviewInvoice = lazy(() =>
  import("../modules/invoicemgmnt/preview-invoice")
);
// Service Management
//const ServiceLanding = lazy(() => import('../modules/servicemgmnt/landing'));
//const ServiceDetails = lazy(() => import('../modules/servicemgmnt/service-details'));
//const ServiceListing = lazy(() => import('../modules/servicemgmnt/service-listing'));
const ManageCategories = lazy(() =>
  import("../modules/servicemgmnt/categories")
);
const ManageCatalogue = lazy(() => import("../modules/servicemgmnt/catalogue"));
// Admin Management
const ManageUsers = lazy(() =>
  import("../modules/adminmgmnt/usermgmnt/manage-users")
);
const ManageRoles = lazy(() =>
  import("../modules/adminmgmnt/usermgmnt/manage-roles")
);
const DelegateRole = lazy(() =>
  import("../modules/adminmgmnt/usermgmnt/delegate-role")
);
const LookupParams = lazy(() =>
  import("../modules/adminmgmnt/administration/lookup-parameters")
);
const SetupNotification = lazy(() =>
  import("../modules/adminmgmnt/administration/setup-notification")
);
const ManageMasterData = lazy(() =>
  import("../modules/adminmgmnt/administration/manage-master-data")
);
const ViewMstLog = lazy(() =>
  import("../modules/adminmgmnt/administration/manage-master-data/view-log")
);
const MasterDataDetail = lazy(() =>
  import("../modules/adminmgmnt/administration/master-data-detail")
);
const InterfaceMonitor = lazy(() =>
  import("../modules/adminmgmnt/administration/interface-monitor")
);
const InterfaceMonitorDetail = lazy(() =>
  import("../modules/adminmgmnt/administration/interface-monitor-detail")
);
const DeptApproversSetup = lazy(() =>
  import("../modules/adminmgmnt/administration/dept-approvers-setup")
);
const AmtBasedApproversSetup = lazy(() =>
  import("../modules/adminmgmnt/administration/amtbased-approvers-setup")
);
//const TaskMonitor = lazy(() => import('../modules/adminmgmnt/administration/task-monitor'));
const NotificationDetail = lazy(() =>
  import("../modules/common/notification-detail")
);
const BasicSettings = lazy(() =>
  import("../modules/adminmgmnt/administration/basic-settings")
);
const CostCenters = lazy(() =>
  import("../modules/adminmgmnt/administration/cost-centers")
);
// Spend Analysis
const SpendAnalysis = lazy(() => import("../modules/spend-analysis"));

const SpendDetails = lazy(() => import("../modules/spend-details"));

// Budgets
const Budgets = lazy(() => import("../modules/budgets/budget-landing"));
const PreviewBudget = lazy(() => import("../modules/budgets/budget-preview"));
// Inventory
const Inventory = lazy(() => import("../modules/inventory/inventory-landing"));
const InventoryPreview = lazy(() =>
  import("../modules/inventory/inventory-preview")
);
// Subscription
const SubscriptionMgmt = lazy(() => import("../modules/subscriptionmgmt"));
const SubscriptionCheckout = lazy(() =>
  import("../modules/subscriptionmgmt/checkout")
);
const ExpiredSubscription = lazy(() =>
  import("../modules/subscriptionmgmt/expired-subscription")
);
const PaymentResponse = lazy(() =>
  import("../modules/subscriptionmgmt/payment-response")
);
// Bids
const BidsLanding = lazy(() => import("../modules/bidsmgmt/bids-landing"));
const BidsCreate = lazy(() => import("../modules/bidsmgmt/bids-create"));
const BidsView = lazy(() => import("../modules/bidsmgmt/bids-view"));
const BidsSuppView = lazy(() => import("../modules/bidsmgmt/bids-supp-view"));
const SupplierBids = lazy(() =>
  import("../modules/bidsmgmt/bids-supplier-landing")
);
const BidsResponse = lazy(() => import("../modules/bidsmgmt/bids-response"));
const BidsResponseView = lazy(() =>
  import("../modules/bidsmgmt/bids-response-view")
);
const BidsEvaluateResponse = lazy(() =>
  import("../modules/bidsmgmt/bids-evaluate-response")
);
const BidsResponseLinescore = lazy(() =>
  import("../modules/bidsmgmt/bids-response-linescore")
);
const BidsTechResponsescore = lazy(() =>
  import("../modules/bidsmgmt/bids-tech-response")
);
const BidsTechScore = lazy(() =>
  import("../modules/bidsmgmt/bids-evaluate-response/bids-technical-score")
);
const BidsCommScore = lazy(() =>
  import("../modules/bidsmgmt/bids-evaluate-response/bids-comm-score")
);
const CompareResponses = lazy(() =>
  import("../modules/bidsmgmt/bid-compare-responses")
);
const AwardBidDetail = lazy(() =>
  import("../modules/bidsmgmt/bid-award-details")
);
// Contracts
const ContractsLanding = lazy(() =>
  import("../modules/contractsmgmt/contracts-landing")
);
const ContractsPreview = lazy(() =>
  import("../modules/contractsmgmt/contracts-preview")
);
const ContractsSupplierLanding = lazy(() =>
  import("../modules/contractsmgmt/contracts-supplier-landing")
);
const SupplierContractPreview = lazy(() =>
  import("../modules/contractsmgmt/contracts-supplier-preview")
);
const ContractsSections = lazy(() =>
  import("../modules/contractsmgmt/contracts-sections")
);
const ContractsTerms = lazy(() =>
  import("../modules/contractsmgmt/contracts-terms")
);
const ContractsTempltes = lazy(() =>
  import("../modules/contractsmgmt/contracts-templates")
);

// My Tasks
const MyTasks = lazy(() => import("../modules/common/mytasks"));
// Reports
const MyReports = lazy(() => import("../modules/reports"));
const ReportView = lazy(() => import("../modules/reports/report-view"));
// Account Manage
const Account = lazy(() => import("../modules/common/account"));
// ProductsManage
const ManageProducts = lazy(() => import("../modules/products"));
const CreateProduct = lazy(() => import("../modules/products/create-product"));
const EditProduct = lazy(() => import("../modules/products/edit-product"));
const ViewProduct = lazy(() => import("../modules/products/view-product"));

//Auctions
const AuctionsLanding = lazy(() => import("../modules/events"));
const AuctionDetails = lazy(() => import("../modules/events/auction-details"));
const CompareAuctions = lazy(() => import("../modules/events/compare-bids"));
const SupplierAuctionLanding = lazy(() =>
  import("../modules/events/supplier-auctions")
);
const SupplierAuctionView = lazy(() =>
  import("../modules/events/supplier-auction-view")
);
const SupplierUpdateResponse = lazy(() =>
  import("../modules/events/supplier-auction-update-response")
);
const SupplierEditResponse = lazy(() =>
  import("../modules/events/supplier-auction-edit-response")
);
const SupplierAuctionResponseView = lazy(() =>
  import("../modules/events/supplier-auction-response-view")
);
const AwardAuctions = lazy(() => import("../modules/events/award-auctions"));
const AuctionDetailedView = lazy(() =>
  import("../modules/events/detailed-view")
);
const AwardAuctionDetail = lazy(() =>
  import("../modules/events/award-auction-details")
);
const AwardAuctionList = lazy(() =>
  import("../modules/events/award-auctions-list")
);

// loader
const loadingBlock = (
  <div className="loader-block">
    <div className="loader" />
  </div>
);

// routes list
const landingRoutes = [
  "/",
  "/signin",
  "/signup",
  "/forgotpassword",
  "/resetpassword",
  "/about-us",
  "/products",
  "/plans",
  "/free-trail",
  "/book-demo",
  "/contact-us",
  "/supplier-management",
  "/contract-management",
  "/purchase-requisition",
  "/e-invoicing",
  "/e-sourcing",
  "/spend-analytics",
  "/integrations",
  "/blogs",
  "/blog-details/:name",
  "/privacy-policy",
  "/careers",
];
const secondLevelRouters = [
  "/company-details",
  "/scope-of-supply",
  "/contact-details",
  "/banking",
  "/certificates",
  "/supplier-preview",
  "/user-account",
  "/user-feedback",
];

export default class AppRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkToBack: null,
    };
  }

  changeLinkToBack = (linkToBack) => {
    this.setState({ linkToBack });
  };

  render() {
    const state = this.state;

    return (
      <Router>
        <Route exact path={landingRoutes}>
          <Suspense fallback={loadingBlock}>
            <Switch>
              {/* landing module */}
              <Route path="/" component={SiteLanding} exact />
              <Route path="/signin" component={Signin} />
              <Route path="/signup" component={Signup} />
              <Route path="/forgotpassword" component={Forgotpassword} />
              <Route path="/resetpassword" component={Resetpassword} />
              <Route path="/about-us" component={AboutUs} />
              <Route path="/products" component={Products} />
              <Route path="/plans" component={Pricing} />
              <Route path="/free-trail" component={FreeTrail} />
              <Route path="/book-demo" component={BookDemo} />
              <Route path="/contact-us" component={ContactUs} />
              <Route
                path="/supplier-management"
                component={SupplierManagement}
              />
              <Route
                path="/contract-management"
                component={ContractManagement}
              />
              <Route
                path="/purchase-requisition"
                component={PurchaseRequisition}
              />
              <Route path="/e-invoicing" component={EInvoicing} />
              <Route path="/e-sourcing" component={ESourcing} />
              <Route path="/spend-analytics" component={SpendAnalytics} />
              <Route path="/integrations" component={Integrations} />
              <Route path="/blogs" component={Blogs} />
              <Route path="/blog-details/:name" component={BlogDetails} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/careers" component={Careers} />
            </Switch>
          </Suspense>
        </Route>

        <Route exact path={secondLevelRouters}>
          <Suspense fallback={loadingBlock}>
            <Switch>
              <Route path="/company-details" component={CompanyDetails} />
              <Route path="/contact-details" component={ContactDetails} />
              <Route path="/scope-of-supply" component={ScopeofSupply} />
              <Route path="/certificates" component={Certificates} />
              <Route path="/banking" component={Banking} />
              <Route path="/supplier-preview" component={SupplierPreview} />
              <LayoutHOC disableBlock>
                <Route path="/user-feedback" component={UserFeedback} />
                <Route path="/user-account" component={Account} />
              </LayoutHOC>
            </Switch>
          </Suspense>
        </Route>

        <Route>
          <Suspense fallback={loadingBlock}>
            {/* <LayoutHOC> */}
            <Switch>
              {/* common module */}
              <HeaderLayout path="/forbidden" component={Forbidden} />
              <HeaderLayout path="/account" component={Account} />
              <HeaderLayout path="/my-tasks" component={MyTasks} />
              <HeaderLayout
                path="/notification-detail"
                component={NotificationDetail}
              />
              <HeaderLayout path="/feedback" component={UserFeedback} />
              {/* dashboard module */}
              <HeaderLayout path="/dashboard" component={Dashboard} />
              {/* Catalouge module */}
              <HeaderLayout
                path="/manage-categories"
                component={ManageCategories}
              />
              <HeaderLayout
                path="/manage-catalogue"
                component={ManageCatalogue}
              />
              {/* Admin Management */}
              <HeaderLayout path="/basic-settings" component={BasicSettings} />
              <HeaderLayout
                path="/lookup-parameters"
                component={LookupParams}
              />
              <HeaderLayout path="/cost-centers" component={CostCenters} />
              <HeaderLayout
                path="/dept-approvers-setup"
                component={DeptApproversSetup}
              />
              <HeaderLayout
                path="/amtbased-approvers-setup"
                component={AmtBasedApproversSetup}
              />
              <HeaderLayout
                path="/setup-notification"
                component={SetupNotification}
              />
              {/* User Management Module*/}
              <HeaderLayout path="/manage-users" component={ManageUsers} />
              <HeaderLayout path="/manage-roles" component={ManageRoles} />
              <HeaderLayout path="/delegate-role" component={DelegateRole} />
              {/* supplier module */}
              <HeaderLayout path="/supplier" component={SupplierListing} />
              <HeaderLayout
                path="/supplier-approval"
                component={SupplierApproval}
                title="Profile Preview"
                linkToBack={state.linkToBack ?? "/supplier"}
                changeLinkToBack={this.changeLinkToBack}
              />
              {/* esource */}
              <HeaderLayout path="/sourcing" component={PRLanding} />
              <HeaderLayout
                path="/purchase-preview"
                component={PRPreview}
                title="Purchase Preview"
                linkToBack={state.linkToBack ?? "/sourcing"}
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout path="/po-landing" component={POLanding} />
              <HeaderLayout
                path="/po-preview"
                component={POPreview}
                title="Purchase Order Preview"
                linkToBack={state.linkToBack ?? "/po-landing"}
                changeLinkToBack={this.changeLinkToBack}
              />
              {/* Invoices */}
              <HeaderLayout path="/invoices" component={InvoiceLanding} />
              <HeaderLayout path="/non-po-invoice" component={NonPOInvoice} />
              <HeaderLayout
                path="/invoice-preview"
                component={PreviewInvoice}
                title="Invoice Preview"
                linkToBack={state.linkToBack ?? "/invoices"}
                changeLinkToBack={this.changeLinkToBack}
              />
              {/* Budget */}
              <HeaderLayout path="/budgets" component={Budgets} />
              <HeaderLayout
                path="/preview-budget"
                component={PreviewBudget}
                title="Budgets Preview"
                linkToBack={state.linkToBack ?? "/budgets"}
                changeLinkToBack={this.changeLinkToBack}
              />
              {/* Integration Module*/}
              <HeaderLayout
                path="/manage-master-data"
                component={ManageMasterData}
              />
              <HeaderLayout path="/viewMstLog" component={ViewMstLog} />
              <HeaderLayout
                path="/interface-monitor"
                component={InterfaceMonitor}
              />
              <HeaderLayout
                path="/interface-monitor-detail"
                component={InterfaceMonitorDetail}
              />
              <HeaderLayout
                path="/master-data-detail"
                component={MasterDataDetail}
              />
              {/* Spend Analysis */}
              <HeaderLayout path="/spend-analysis" component={SpendAnalysis} />
              <HeaderLayout
                path="/spend-details"
                component={SpendDetails}
                linkToBack="/spend-analysis"
                title="Spend Details"
                changeLinkToBack={this.changeLinkToBack}
              />

              {/* Reports */}
              <HeaderLayout path="/myreports" component={MyReports} />
              <HeaderLayout
                path="/report-details"
                component={ReportView}
                linkToBack="/myreports"
                title="Report Preview"
                changeLinkToBack={this.changeLinkToBack}
              />

              {/* Subscription Mgmt */}
              <HeaderLayout
                path="/my-subscription"
                component={SubscriptionMgmt}
              />
              <HeaderLayout
                path="/checkout"
                linkToBack="/my-subscription"
                title="Pay with Checkout"
                component={SubscriptionCheckout}
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout path="/end-trail" component={ExpiredSubscription} />
              <HeaderLayout
                path="/payment-response"
                component={PaymentResponse}
              />
              {/* Inventory */}
              <HeaderLayout path="/inventory" component={Inventory} />
              <HeaderLayout
                path="/inventory-preview"
                component={InventoryPreview}
                title="Inventory Preview"
                linkToBack={state.linkToBack ?? "/inventory"}
                changeLinkToBack={this.changeLinkToBack}
              />
              {/* Bids */}
              <HeaderLayout path="/bids" component={BidsLanding} />
              <HeaderLayout
                path="/bid-create"
                component={BidsCreate}
                title="Bids Create"
                linkToBack="/bids"
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/bid-view"
                component={BidsView}
                title="View Bid"
                linkToBack={state.linkToBack ?? "/bids"}
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/bid-evaluate-response"
                component={BidsEvaluateResponse}
                title="Analyze Bid Responses"
                linkToBack={state.linkToBack ?? "/bids"}
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout path="/supplierBids" component={SupplierBids} />
              <HeaderLayout
                path="/bid-supplierView"
                component={BidsSuppView}
                title="View Bid"
                linkToBack="/supplierBids"
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/bid-response"
                component={BidsResponse}
                title="Bid Response"
                linkToBack="/supplierBids"
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/bid-response-view"
                component={BidsResponseView}
                title="View Bid Response"
                linkToBack="/supplierBids"
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/bid-response-linescore"
                component={BidsResponseLinescore}
                title="Bid Responses Scoring"
                linkToBack="/bids"
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/bid-tech-linescore"
                component={BidsTechResponsescore}
                title="Bid Responses Scoring"
                linkToBack="/bids"
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/bid-tech-score"
                component={BidsTechScore}
                title="Bid Technical Scoring"
                linkToBack={state.linkToBack ?? "/bids"}
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/bid-comm-score"
                component={BidsCommScore}
                title="Bid Commercial Scoring"
                linkToBack={state.linkToBack ?? "/bids"}
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/bid-compare-responses"
                component={CompareResponses}
                title="Compare Bids"
                linkToBack="/bids"
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/bid-award-details"
                component={AwardBidDetail}
                title="Bid Award Details"
                linkToBack={state.linkToBack ?? "/bids"}
                changeLinkToBack={this.changeLinkToBack}
              />
              {/* Contracts */}
              <HeaderLayout path="/contracts" component={ContractsLanding} />
              <HeaderLayout
                path="/contract-preview"
                component={ContractsPreview}
                title="Contract Preview"
                linkToBack={state.linkToBack ?? "/contracts"}
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/supplierContracts"
                component={ContractsSupplierLanding}
              />
              <HeaderLayout
                path="/contract-supplierView"
                component={SupplierContractPreview}
                title="View Contract"
                linkToBack="/supplierContracts"
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/contractSections"
                component={ContractsSections}
              />
              <HeaderLayout path="/contractTerms" component={ContractsTerms} />
              <HeaderLayout
                path="/contractTemplates"
                component={ContractsTempltes}
              />
              {/*Products Manage*/}
              <HeaderLayout path="/manage-items" component={ManageProducts} />
              <HeaderLayout path="/create-item" component={CreateProduct} />
              <HeaderLayout path="/edit-item" component={EditProduct} />
              <HeaderLayout
                path="/view-item"
                component={ViewProduct}
                title="View Item"
                linkToBack="/manage-items"
                changeLinkToBack={this.changeLinkToBack}
              />
              {/*Events Manage*/}
              <HeaderLayout
                path="/manage-auctions"
                component={AuctionsLanding}
              />
              <HeaderLayout
                path="/auction-details"
                component={AuctionDetails}
                title="View Auction"
                linkToBack="/manage-auctions"
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/award-auctions/auction-details"
                component={AwardAuctions}
                title="Award Bid"
                linkToBack="/manage-auctions"
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/compare-bids"
                component={CompareAuctions}
                title="COMPARATIVE STATEMENT"
                linkToBack="/manage-auctions"
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/supplierAuctions"
                component={SupplierAuctionLanding}
              />
              <HeaderLayout
                path="/supplierAuction-view"
                component={SupplierAuctionView}
                title="Back"
                linkToBack="/supplierAuctions"
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/supplierAuction-update-response"
                component={SupplierUpdateResponse}
                title="Back"
                linkToBack="/supplierAuctions"
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/supplierAuction-edit-response"
                component={SupplierEditResponse}
                title="Back"
                linkToBack="/supplierAuctions"
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/supplierAuction-response-view"
                component={SupplierAuctionResponseView}
                title="Back"
                linkToBack="/supplierAuctions"
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/award-auctions"
                component={AwardAuctions}
                title="Award Bid"
                // linkToBack="/manage-auctions"
              />
              <HeaderLayout
                path="/auction-detailed-view"
                component={AuctionDetailedView}
                title="Detailed View"
                linkToBack="/manage-auctions"
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/auction-award-details"
                component={AwardAuctionDetail}
                title="Auction Award Details"
                linkToBack={state.linkToBack ?? "/manage-auctions"}
                changeLinkToBack={this.changeLinkToBack}
              />
              <HeaderLayout
                path="/auction-award-list"
                component={AwardAuctionList}
                title="Auction Award Details"
                linkToBack="/manage-auctions"
                changeLinkToBack={this.changeLinkToBack}
              />
            </Switch>
            {/* </LayoutHOC> */}
          </Suspense>
        </Route>

        {/* ChatBot End */}
        <ToastContainer
          autoClose={3000}
          limit={1}
          rtl={false}
          hideProgressBar
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{ width: "auto" }}
        />
      </Router>
    );
  }
}
